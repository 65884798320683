import * as React from 'react';
// import { MDXRenderer } from "gatsby-plugin-mdx";
import Layout from '../../../components/Layout';
import { graphql } from 'gatsby';
import EventsArchive from '../../../components/EventsArchive';
import SiteHeaderCity from '../../../components/SiteHeaderCity';
export { default as Head } from '../../../components/Head';

const Page = ({ data }) => {
  const events = data?.events?.nodes;
  return (
    <div id="noco">
      <SiteHeaderCity/>
      <Layout>
        <div className='page-title main copy'>
          <div className="columns is-multiline">
            <div className="content-intro column">
              <h1>PRÓXIMOS<br/><span>EVENTOS</span></h1>
              <div className="line-wrapper">
                <div className="h2underline"></div>
                <div className="marginline"></div>
              </div>
            </div>
            <div className='column'>
              <p>Antes del evento evangelístico, concentraremos nuestros esfuerzos en orar, energizar a la iglesia, capacitar a los cristianos para que sepan compartir su fe, dar a conocer el evento e invitar amigos.</p>
            </div>
          </div>
        </div>
        <div className="container main noco">
          <EventsArchive events={events} />
        </div>
      </Layout>
    </div>
  );
};

export const pageQuery = graphql`
  query {
    events: allMdx(filter: { fields: { slug: { glob: "/es/noco/eventos/*" } } } sort: { fields: frontmatter___date }) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          title
          date
          date2
          date3
          start_time
          category
          venue_name
          subcategory
          slug
        }
        excerpt
      }
    }
  }
`;

export default Page;
